<template>
  <div>
    <van-nav-bar
      :title="company"
      left-text=""
      left-arrow
      class="navbar"
      @click-left="goBack"
    />
    <div class="qxjzcy">
      <div class="jzimg">
        <img
          src="https://lesson.iapeap.com/images/EmotionalValue/qxjzcy2.png"
          alt=""
        />
      </div>
      <div class="content">
        <div class="ctx1">
          欢迎来到情绪能量测试，你需要完成一系列与情绪价值有关的题目，大概需要10-20分钟的时间。
        </div>
        <div class="ctx2">
          这些问题的选项并没有“正确”或“错误之分，你只需要根据题目的说明，选择最适合你的选项就可以。
        </div>
        <div class="ctx2">
          在4阶的练习完成后，你可以再进行一次测试，看到自己的变化和收获。
        </div>
        <div class="ctxbtn">
          <button @click="toworthtest">开始测试</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { memberLog } from "../../api/login";

export default {
  data() {
    return {
      item: {},
      starttime: "", //  进入页面时间
      endtime: "", //  退出页面时间
      time: "", //   页面累积时间
      unitId: window.localStorage.getItem("unitId"), //公司id
      company: "", //公司
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    toworthtest() {
      this.$router.push({
        path: "/test/worthtest",
        query: { item: this.item },
      });
      window.localStorage.setItem("subid", this.item.id);
      console.log(this.item);
    },
  },
  mounted() {
    if (this.unitId == "undefined") {
      this.company = "高情商训练营";
    }
    if (this.unitId == "sys1818") {
      this.company = "情绪价值训练营";
    }
    if (this.unitId == "1397") {
      this.company = "高情商训练营";
    }
    if (this.$route.query.item) {
      this.id = this.$route.query.item.id;
      this.item = this.$route.query.item;
      console.log(this.id);
    }
    this.timer = setInterval(() => {
      this.time++;
      // console.log("页面累积浏览时间是：",this.time,"秒")
    }, 1000); // 每秒触发一次
    // ```````````获取当前的年月日时分秒，并以"2018-01-01 0:00"的格式显示
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 补0操作，确保是两位数
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");

    this.starttime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log("进入页面的系统时间是：", this.starttime);
  },
  beforeDestroy() {
    clearInterval(this.timer); // 清除定时器
    // ```````````获取当前的年月日时分秒，并以"2018-01-01 0:00"的格式显示
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 补0操作，确保是两位数
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    this.endttime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log("离开页面的系统时间是", this.endttime);

    //把参数传给后端接口，starttime、endttime、time
    let data = {
      startTime: this.starttime,
      endTime: this.endttime,
      userId: window.localStorage.getItem("userId"),
      pageName: "情绪能量测验",
      timeStamp: this.time,
    };
    memberLog(data).then((res) => {});
  },
};
</script>
<style lang="less">
.navbar {
  background-color: #ededed;
}
/deep/ .van-nav-bar .van-icon {
  color: #464646;
}
.qxjzcy {
  background-color: #f4fafe;
  // min-height: 100vh;
  padding: 0px 20px 0px 20px;

  .jzimg {
    display: flex;
    justify-content: center;
    img {
      width: 181px;
      height: 116px;
    }
  }
  .content {
    box-sizing: border-box;
    height: 452px;
    background-color: #fff;
    box-shadow: 2px 2px 8px 2px rgba(185, 184, 184, 0.5);
    border-radius: 8px;
    position: relative;
    bottom: 16px;
    padding: 40px 20px;
    .ctx1 {
      text-indent: 20px;
      line-height: 30px;
    }
    .ctx2 {
      text-indent: 20px;
      line-height: 30px;
    }
    .ctxbtn {
      display: flex;
      justify-content: center;
      margin-top: 100px;
      button {
        width: 162px;
        height: 36px;
        line-height: 36px;
        color: #fff;
        border-radius: 50px;
        background-color: #72acf4;
        border: 0;
      }
    }
  }
}
</style>
